var linkReviewPage = "https://www.car-actis.be/avis/";
var connectionId = 20;

$(document).ready(function() {
    
    $.ajax('https://www.lws-reviews.be/connections/facebook/' + connectionId + '/reviews', {
        success: function(response) {

            var reviews     = response.data;
            var nbReviews   = reviews.length;
            var totalRating = 0;
            var avgRating   = 0;

            for(var reviewIndex in reviews) {
                let review = reviews[reviewIndex];

                if(!isNaN(review.rating)) {
                    totalRating += review.rating;
                } else {
                    if(review.recommendation_type == "positive") {
                        totalRating += 5;
                        reviews[reviewIndex].rating = 5;
                    } else {
                        reviews[reviewIndex].rating = 0;
                    }
                }
            }

            avgRating = Math.round(totalRating / nbReviews);

            if(!getCookie('close-avg-reviews-widget')) {
                // Create widget and append it to the end of body
                var elem = createAvgWidget(nbReviews, avgRating);
                $('body').append(elem);

                $('.lws-reviews-avg-widget .close-btn').on('click',function(e){
                    e.preventDefault();
                    $(this).parent().fadeOut();
                    setCookie('close-avg-reviews-widget', '1', 30);
                });
            }

            // Check if we are on a listing page
            if($('#lws-reviews-load').length) {
                for(var reviewIndex in reviews) {
                    let review = reviews[reviewIndex];
                    var listItem = createListWidgetItem(review);
                    $('#lws-reviews-load ul').append(listItem);
                }
            }
        }
    });

});

function createAvgWidget(nbReviews, avgRating) {
    var widgetHtml = [
        '<a href="'+ linkReviewPage +'" class="lws-reviews-avg-widget">',
            '<i class="fal fa-times close-btn"></i>',
            '<h5>Avis clients</h5>',
            '<div class="avg-widget-facebook d-flex align-items-center">',
                '<div class="avg-widget-facebook-logo"><img src="https://www.car-actis.be/wp-content/themes/car-actis/feed/resources/images/icon-facebook.png" ></div>',
                '<div class="avg-widget-facebook-ratings">' + avgRating + '</div>',
                '<div class="avg-widget-facebook-stars value-' + avgRating + '">',
                    '<i class="fas fa-star star-1"></i>',
                    '<i class="fas fa-star star-2"></i>',
                    '<i class="fas fa-star star-3"></i>',
                    '<i class="fas fa-star star-4"></i>',
                    '<i class="fas fa-star star-5"></i>',
                '</div>',
                '<div class="avg-widget-facebook-count">(' + nbReviews + ' avis)</div>',
            '</div>',
        '</a>',
    ];

    return $(widgetHtml.join(''));
}

function createListWidgetItem(review) {
    var avisDate = new Date(review.created_time*1000).toLocaleDateString("fr-FR");
    var widgetHtml = [];
        widgetHtml.push('<li class="lws-reviews-list-item">');
        widgetHtml.push('<span class="lws-reviews-list-item-wrapper">');
            widgetHtml.push('<span class="lws-reviews-list-item-header">');
                widgetHtml.push('<span class="lws-reviews-list-item-header-left">');
                widgetHtml.push('<img class="lws-reviews-list-item-logo" src="https://www.mycompositi.com/themes/compositi/assets/img/icon-facebook.png" alt="">');
                if(!isNaN(review.rating)) {
                    widgetHtml.push('<span class="lws-reviews-list-item-rating">');
                    if(review.rating >= 1) widgetHtml.push('<i class="fas fa-star active"></i>');
                    else widgetHtml.push('<i class="fas fa-star"></i>');
                    if(review.rating >= 2) widgetHtml.push('<i class="fas fa-star active"></i>');
                    else widgetHtml.push('<i class="fas fa-star"></i>');
                    if(review.rating >= 3) widgetHtml.push('<i class="fas fa-star active"></i>');
                    else widgetHtml.push('<i class="fas fa-star"></i>');
                    if(review.rating >= 4) widgetHtml.push('<i class="fas fa-star active"></i>');
                    else widgetHtml.push('<i class="fas fa-star"></i>');
                    if(review.rating >= 5) widgetHtml.push('<i class="fas fa-star active"></i>');
                    else widgetHtml.push('<i class="fas fa-star"></i>');
                    widgetHtml.push('</span>');
                }
                widgetHtml.push('</span>');
            widgetHtml.push('</span>');
            widgetHtml.push('<span class="lws-reviews-list-item-bottom">');
                widgetHtml.push('<span class="lws-reviews-list-item-name"></span>');
                widgetHtml.push('<span class="lws-reviews-list-item-date">' + avisDate + '</span>');
            widgetHtml.push('</span>');
            widgetHtml.push('<span class="lws-reviews-list-item-content">');
            widgetHtml.push('<div class="lws-reviews-list-item-reviewer">');
            widgetHtml.push('<img src="'+review.reviewer.picture.data.url+'" class="reviewer-avatar" >');
            widgetHtml.push(review.reviewer.name);
            widgetHtml.push('</div>');
                widgetHtml.push(review.review_text);
            widgetHtml.push('</span>');
        widgetHtml.push('</span>');
        widgetHtml.push('</li>');
    

    return $(widgetHtml.join(''));
}

function setCookie(name, value, days) {
    var expires = "";
    if (days) {
      var date = new Date();
      date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
      expires = "; expires=" + date.toUTCString();
    }
    document.cookie = name + "=" + (value || "") + expires + "; path=/";
  }
  
  function getCookie(name) {
    var nameEQ = name + "=";
    var ca = document.cookie.split(';');
    for (var i = 0; i < ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) == ' ') c = c.substring(1, c.length);
      if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
    }
    return false;
  }